<!--
 * @Author: wjc
 * @Date: 2023-12-08 14:51:37
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 16:51:30
 * @Description: 
-->
<template>
  <transition name="fade" @enter="lockBodyScroll" @after-leave="unlockBodyScroll">
    <div v-if="open" class="screen-wrap" ref="screen">
      <div class="container">
        <nav class="screen-menu">
          <template v-for="menu in groupList" :key="menu.id">
            <template v-if="menu.children && menu.children.length > 0">
              <MenuGroup :menu="menu"></MenuGroup>
            </template>
            <template v-else>
              <div class="link py-12px">
                <MLink :to="menu.to || `/content/${menu.id}`" @click="closeScreen">
                  <span class="hover:text-link-color transition-color-250">
                    {{ menu.name }}
                  </span>
                </MLink>
              </div>
            </template>
          </template>
        </nav>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
  import { useOptionStore } from '~/stores/modules/option'
  import MenuGroup from './menu-group.vue'
  import type { IInfoLevel } from '~/models/activity'

  defineOptions({
    name: 'MenuScreen',
  })

  defineProps<{
    open: boolean
  }>()

  const optionStore = useOptionStore()
  const groupList = computed<IInfoLevel[]>(() => optionStore.menus)

  const closeScreen = inject('close-screen') as () => void

  const screen = ref<HTMLElement | null>(null)

  function lockBodyScroll() {
    disableBodyScroll(screen.value!, { reserveScrollBarGap: true })
  }

  function unlockBodyScroll() {
    clearAllBodyScrollLocks()
  }
</script>

<style scoped lang="scss">
  .screen-wrap {
    @apply z-101 fixed top-80px right-0 bottom-0 left-0 px-0 py-32px w-full bg-bg-page overflow-y-auto;
    transition: background-color 0.5s;
  }

  .screen-wrap.fade-enter-active,
  .screen-wrap.fade-leave-active {
    transition: opacity 0.25s;
  }

  .screen-wrap.fade-enter-active .container,
  .screen-wrap.fade-leave-active .container {
    transition: transform 0.25s ease;
  }

  .screen-wrap.fade-enter-from,
  .screen-wrap.fade-leave-to {
    opacity: 0;
  }

  .screen-wrap.fade-enter-from .container,
  .screen-wrap.fade-leave-to .container {
    transform: translateY(-8px);
  }

  .container {
    margin: 0 auto;
    padding: 24px 0 96px;
    max-width: 288px;
  }

  .menu + .appearance {
    margin: 24px auto;
  }

  .menu + .social-links {
    margin-top: 16px;
  }

  .appearance + .social-links {
    margin-top: 12px;
  }
  .screen-menu {
    @apply font-s-16px;

    .link {
      @apply py-12px overflow-hidden text-color-1;
      border-bottom: 1px solid var(--bg-divider);
      transition: border-color 0.5s;
    }
  }
</style>
